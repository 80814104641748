@use '@angular/material' as mat;
@import "./app/scss/_global.scss";

@font-face {
  font-family: "Galderglynn Book";
  src: url(./assets/fonts/galderglynn-book.otf);
}

@font-face {
  font-family: "Galderglynn Light";
  src: url(./assets/fonts/galderglynn-light.otf);
}

@font-face {
  font-family: "Palatino Linotype";
  src: url(./assets/fonts/palatino-linotype.ttf);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $main-background-dark;
  color: $main-font-color-dark;
}

a {
  all: unset;
}
.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #9e9e9e !important;
}